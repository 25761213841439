<template>
  <div>
   
    <div class="topWrap">
          <div class="content">
               <h3>忘记密码</h3>
               <el-form :model="form" :rules="rules" ref="ruleForm" :show-message="false">
                 <el-form-item prop="mobileOrEmail">
                  <el-input v-model="form.mobileOrEmail" placeholder="请输入手机号码">
                     <i  slot="prefix" class="iconfont"> &#xe641; </i>
                  </el-input>
                </el-form-item>
                  <el-form-item prop="verifyCode">
                  <el-input v-model="form.verifyCode" placeholder="请输入短信验证码">
                    <i  slot="prefix" class="iconfont"> &#xe647; </i>
                    <em slot="suffix" class="yzm" @click="sendVerifyCode"> {{sendBtnText}}  </em>
                  </el-input>
                </el-form-item>
                 <el-form-item prop="newPassword">
                  <el-input v-model="form.newPassword" type="password" placeholder="设置新的登录密码(6至20位)">
                     <i  slot="prefix" class="iconfont"> &#xe65d; </i>
                  </el-input>
                </el-form-item>
                   <el-form-item>
                    <el-button type="primary" @click="onSubmit">确认</el-button>
                  </el-form-item>
              </el-form>  
                
          </div>
    </div>

  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
import {forgetPassword} from '@/api/UserService'
import {sendSmsVerifyCode} from '@/api/SmsService'
export default {
  name: 'Login',
  components: {
    slider,
    slideritem
  },
  mounted () {
    this.init()
  },
     data () {
      return {
         sendBtnText:'发送验证码',
        sendStatus:-1,
        form:{
          mobileOrEmail:'',
          verifyCode:'',
          newPassword:'',
        },
         rules: {
          mobileOrEmail: [
            { required: true, message: '请输入手机号码', trigger: 'blur' }
          ],
         }
      }
    },

  methods: {
    init () {
      // this.axiosPost("/export/export/unLoadNum",{'user':'gff','pwd':'111'})
      //             .then(res => {
      //                  console.log("response...")
      //             })
    },
     sendVerifyCode(){
      if(this.sendStatus!=-1){
        return false;
      }
      if(!this.form.mobileOrEmail){
        this.$message.error('请填写手机号码');
        return
      }
      this.sendStatus=0;
      this.sendBtnText='发送中';

      sendSmsVerifyCode(`+86-${this.form.mobileOrEmail}`,'forget-pwd').then(({data,res})=>{
          this.form.verifyCodeId=data;
          this.sendStatus=1;

          let that=this;
          let count=90;
          let refreshVerifyCode=()=>{
              if(count==0){
                 that.sendBtnText='重新发送';
                 that.sendStatus=-1;
                 return false;
              }else{
                 count--;
                 that.sendBtnText=`${count}秒后重发`;
                 setTimeout(refreshVerifyCode,1000);
              }
          }
          setTimeout(refreshVerifyCode,1000);
      }).catch(e=>{
        this.sendBtnText='发送验证码';
        this.sendStatus=-1;
        console.log(e);
      });
    },
    onSubmit(){
          const params={
              ...this.form,
              mobileOrEmail:`+86-${this.form.mobileOrEmail}`
          }
         forgetPassword(params).then((data)=>{
         this.$router.push({path: '/Login'});
      }).catch(e=>{
          console.log(e);
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/css/loginRigist.scss";
</style>